import React, { useEffect, useState,useContext } from "react";
import chemicalbg from "../../../assets/images/chemical-bg-01.jpeg";
import axios from "axios";
import { baseUrl } from "../../../config/baseUrl";
import { Link } from "react-router-dom";
// import AddCategory from "../Category/AddCategory";
// import AddSubCatgory from "../SubCategory/AddSubCatgory";
// import loaderContext from "../../Context/LoaderContext";
import loaderContext from "../../../context/LoaderContext";
const Chemicalcds = () => {
  const [healthList, setHealthList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [healthDocData, setHealthDocData] = useState([]);
  let user = JSON.parse(localStorage.getItem("user"));

  let {loader, setLoader } = useContext(loaderContext);

  useEffect(() => {
    setLoader(true)
    fetchCategory();
    fetchSubCategory();
    fetchHealth();
    // setLoader(false)
  }, []);

  const fetchHealth = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/module/admin/helth-document-viewset`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log(response.data.data);
      setHealthDocData(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }finally{
      setLoader(false)
    }
  };
  // fetch cat api
  const fetchCategory = async () => {
    try {
      const res = await axios.get(`${baseUrl}/module/admin/health-viewset`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      // console.log("category", res.data.data)
      setHealthList(res.data.data);
    } catch (error) {
      console.log("fetch category error", error);
    }finally{
  
    }
  };
  // fetch subcat api
  const fetchSubCategory = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/module/admin/subcategory-viewset`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log("sub category", res.data.data)
      setSubCatList(res.data.data);
    } catch (error) {
      console.log("fetch sub category error", error);
    }finally{
      
    }
  };
  return (
    
    <>
     {  loader ? <div className= {`${loader ? "loader-main" : ""}`} >
    <div className={`${loader ? "loader" : ""}`}></div></div>
      :  
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa  fa-suitcase me-2" />
                Chemical SDS
              </h4>
              <div>
                <Link
                  to="/health-safety-document-list"
                  className="blu-btn me-2"
                >
                  <i className="fa fa-plus me-2" />
                  health Safety document List
                </Link>
                <Link to="/health-viewset-list" className="blu-btn me-2">
                  <i className="fa fa-plus me-2" />
                  health Safety Policy List
                </Link>
                <Link to="/chemicalsubcategorylist" className="blu-btn">
                  <i className="fa  fa-suitcase me-2" />
                  Chemical SDS list
                </Link>
              </div>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Chemical SDS
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="chemical-bnnr">
              <img src={chemicalbg} className="w-100 rounded mb-3" alt="" />
              <h4 className="text-center fz-20">
              Advanced Facility Care takes Health & Safety seriously and we have put measures in place to ensure that our staff are equip with the correct training and will carry out works in a safe manner at all times .!
              </h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="chemical-listing-card">
              <div className="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#chemical-01"
                    >
                      Chemical SDS
                    </button>
                  </h2>
                  <div id="chemical-01" className="accordion-collapse collapse">
                    <div className="chemical-listing-card-innr">
                      <ul className="chemical-list">
                        {subCatList?.map((arr) => (
                          <li>
                            <a href={arr?.subcategory_document} target="_blank">
                              {arr?.subcategory_name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="chemical-listing-card">
              <div className="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#chemical-02"
                    >
                      Health Safety Document
                    </button>
                  </h2>
                  <div id="chemical-02" className="accordion-collapse collapse">
                    <div className="chemical-listing-card-innr">
                      <ul className="chemical-list">
                        {healthDocData?.map((arr) => (
                          <li>
                            <a href={arr?.helth_document} target="_blank">
                            {arr?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="chemical-listing-card">
              <div className="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#chemical-03"
                    >
                   Health Safety Policy
                    </button>
                  </h2>
                  <div id="chemical-03" className="accordion-collapse collapse">
                    <div className="chemical-listing-card-innr">
                      <ul className="chemical-list">
                        {healthList?.map((arr) => (
                          <li>
                            
                            <Link to={"/health-policy"}>
                        {" "}
                        {arr?.company?.company_name}
                      </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* { user?.user_type !=="SUPER_ADMIN" && <><div class="col-lg-4 col-md-4 col-sm-4"> */}
          {  user?.user_type !=="SUPER_ADMIN" && <>
          {/* <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="chemical-listing-card">
              <div class="accordion">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed icon-none" type="button">
                      <Link to={healthData?.policy_document} target="_blank">
                        {" "}
                        Health & Safety document{" "}
                      </Link>
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div> */} 
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="chemical-listing-card">
              <div class="accordion">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed icon-none" type="button">
                      <Link to={"/health-policy"}>
                        {" "}
                        Health & Safety Policy{" "}
                      </Link>
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
         </>}
        </div>
      </section> 
      }
    </>
  );
};

export default Chemicalcds;
